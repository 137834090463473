import React from "react";

const About = () => {
  return (
    <div class="w-full h-screen flex items-center">
      <div class="text-2xl w-1/2 p-20 ">
        I am 
        <div class="text-red-500">Rohitth Bandaru</div> Highly motivated BITSian intreseted in the software
        development field
      </div>
    </div>
  );
};

export default About;
